<!--
	@name app-events-list
	@description events list component
	@date 2024/08/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div class="mt-10 mb-10">
			<p class="text-body-1 mb-10 mb-md-4">
				Don't miss out on our award winning, industry leading events, brought to you through Air and Air Academy. You're guaranteed to leave feeling inspired and
				equipped with the practical tools and resources you need to support your clients. Book your place early to avoid disappointment, as places are often limited.
			</p>
			<p class="text-body-1 mb-0 d-none d-md-block">
				Make the most of our filters to find the events you're looking for, you can also view the events in
				<a @click="viewOption = 1">calendar</a> or <a @click="viewOption = 2">map</a> view.
			</p>
		</div>

		<v-toolbar flat color="rgba(255,255,255,0)" class="d-none d-md-block events--toolbar">
			<v-select v-model="category" :items="categories" label="Categories" dense hide-details solo outlined style="max-width: 350px" />
			<v-spacer />
			<v-btn-toggle v-model="eventPeriod" mandatory dense>
				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn v-on="on">
							Upcoming
						</v-btn>
					</template>
					<span>Upcoming events</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn v-on="on">
							Past
						</v-btn>
					</template>
					<span>Historic events</span>
				</v-tooltip>
			</v-btn-toggle>
			<v-divider inset vertical class="mx-3" />
			<v-btn-toggle v-model="viewOption" mandatory dense>
				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn v-on="on">
							<v-icon>mdi-view-list-outline</v-icon>
						</v-btn>
					</template>
					<span>Grid view</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn v-on="on">
							<v-icon>mdi-calendar</v-icon>
						</v-btn>
					</template>
					<span>Calendar view</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn v-on="on">
							<v-icon>mdi-map</v-icon>
						</v-btn>
					</template>
					<span>Map view</span>
				</v-tooltip>
			</v-btn-toggle>
		</v-toolbar>
		<p v-if="!featuredEvents.length" class="text-body-1 mt-3 mb-10">
			There are 0 upcoming events for this category
		</p>
		<div v-else v-show="gridActive">
			<v-row class="mt-1">
				<v-col v-for="(event, index) in events" :key="event.id" cols="12" sm="6" :md="index === 0 ? 8 : 4">
					<v-card @click="createEventUrl(event)" hover style="position: relative">
						<v-chip dark label color="rgba(23, 184, 144, 0.7)" class="pl-4 pr-8" style="position: absolute; top: 8px; left: -8px; z-index: 2">
							<v-avatar left rounded color="rgba(103, 0, 86, 0.3)">
								{{ $moment(event.start).format('DD') }}
							</v-avatar>
							<span class="text-body-1 font-weight-bold d-inline-block mr-1">
								{{ $moment(event.start).format('MMMM') }} </span>{{ $moment(event.start).format('YYYY') }}
						</v-chip>
						<v-chip v-if="event.category" small dark label color="rgba(103, 0, 86, 0.5)" style="position: absolute; top: 46px; left: -8px; z-index: 2">
							{{ event.category }}
						</v-chip>
						<app-events-event-countdown v-if="index === 0 && eventPeriod === 0" :event="event" />
						<v-img
							class="white--text align-end"
							:height="'368px'"
							:aspect-ratio="index === 0 ? '2/1' : '1/1'"
							:src="$options.filters.urlize(index === 0 ? event.image : (event.thumbnail || event.image)) || $options.filters.urlize('placeholder/gradient.png')"
						>
							<v-sheet color="rgba(0,0,0,0.75)">
								<v-card-title class="text-body-1 font-weight-bold white--text">
									{{ event.name | truncate(index === 0 && $vuetify.breakpoint.mdAndUp ? 100 : 40) }}
								</v-card-title>
								<v-card-subtitle class="grey--text text--lighten-2 pb-1">
									{{ event.description | truncate(index === 0 && $vuetify.breakpoint.mdAndUp ? 200 : 100) }}
								</v-card-subtitle>
								<v-card-subtitle class="white--text pt-0">
									<v-icon color="white" small style="position: relative; top: -1px; left: -2px">
										mdi-map-marker
									</v-icon>{{ event.address | truncate(index === 0 && $vuetify.breakpoint.mdAndUp ? 100 : 50) }}
								</v-card-subtitle>
							</v-sheet>
						</v-img>
					</v-card>
				</v-col>
			</v-row>
			<div class="text-center mt-3 mb-10">
				<v-btn large outlined :disabled="numberOfEvents >= featuredEvents.length" @click="numberOfEvents = numberOfEvents + 3">
					SEE MORE EVENTS
				</v-btn>
			</div>
		</div>
		<div v-show="calendarActive" class="pt-3">
			<v-sheet elevation="3" color="white" class="mb-10">
				<common-structure-calendar :items="filteredEvents" class="pt-1" />
			</v-sheet>
		</div>
		<div v-show="mapActive" class="pt-3">
			<v-sheet elevation="3" color="white" class="mb-10">
				<app-events-map ref="map" :events="filteredEvents" />
			</v-sheet>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import CommonStructureCalendar from '@/component/common/structure/calendar';
	import AppEventsMap from '@/component/app/events/map';
	import AppEventsEventCountdown from '@/component/app/events/event-countdown';
	import { orderBy, kebabcase } from 'lodash';

	export default {
		name: 'app-events-list',

		components: {
			CommonStructureCalendar,
			AppEventsMap,
			AppEventsEventCountdown
		},

		data() {
			return {
				category: 'All categories',
				categories: null,
				gridCategories: null,
				numberOfEvents: 5,
				viewOption: undefined,
				eventPeriod: 0,
				comparisonDateTime: null
			};
		},

		computed: {
			...mapGetters('CmsEvent', ['upcomingEvents', 'passedEvents']),

			gridActive() {
				return this.viewOption === 0;
			},

			calendarActive() {
				return this.viewOption === 1;
			},

			mapActive() {
				return this.viewOption === 2;
			},

			periodicEvents() {
				return this.eventPeriod === 1 ? this.passedEvents : this.upcomingEvents;
			},

			sortedEvents() {
				if (!this.upcomingEvents || !this.passedEvents) return [];
				if (this.eventPeriod === 1) return this.passedEvents;
				let futureDates = this.upcomingEvents.filter((e) => this.$moment(e.start).isAfter(this.comparisonDateTime));

				return orderBy(
					futureDates,
					[
						'featured',
						(e) => {
							return this.$moment(e.start).format('YYYYMMDD');
						}
					],
					['desc', 'asc']
				);
			},

			filteredEvents() {
				if (!this.category || this.category == 'All categories') return this.sortedEvents;
				return this.sortedEvents.filter((e) => e.category == this.category);
			},

			featuredEvents() {
				if (!this.periodicEvents) return [];
				return this.filteredEvents.filter((e) => this.gridCategories.includes(e.category));
			},

			nextEvent() {
				let event = this.featuredEvents.slice(0, 1)[0];
				if (!event) return {};
				return event;
			},

			events() {
				if (!this.periodicEvents) return [];
				return this.featuredEvents.slice(0, this.numberOfEvents);
			}
		},

		watch: {
			category() {
				if (this.category) {
					if (this.$route.path === '/events') {
						this.$router.push({ path: 'events', query: { category: this.category } });
					}
				}
			}
		},

		async created() {
			this.category = this.$route.query.category ?? this.category;
			const options = await this.getSchemaOptions('event-category');
			this.categories = options?.map(option => option.name);
			this.categories.unshift(this.category);
			this.gridCategories = options?.map(option => option.name);

			if (!this.categories.includes(this.category)) {
				this.category = 'All categories';
				this.$router.push('/events');
			}

			this.comparisonDateTime = this.$moment();
			this.loadEventsList().then(() => {
				this.$nextTick(() => {
					this.$refs.map.init();
				});
			});
		},

		methods: {
			...mapActions('CmsEvent', ['loadEventsList']),
			...mapActions('Option', ['getSchemaOptions']),

			createEventUrl(event) {
				if (this.$route.path === '/events') return this.$router.push(`/event/${kebabcase(event.name)}/${this.$moment(event.start).format('YYYY-MMMM-DD')}/${event.id}`);
				else this.$emit('select-event', event);
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .events--toolbar {
		.v-toolbar__content {
			padding: 0 !important;
		}
	}
</style>
