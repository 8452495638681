<!--
	@name app-login
	@description Register page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row v-if="hasBanner && topBannerImage" no-gutters class="mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="10" offset-md="1" class="text-center">
				<a :href="topBannerLink" target="_blank">
					<v-img :src="topBannerImage" class="mx-auto" />
				</a>
			</v-col>
		</v-row>

		<v-row no-gutters class="pa-6 pt-0 mx-auto" style="max-width: 1280px">
			<v-col cols="3" class="text-center pt-6">
				<a v-if="hasBanner && sideBannerImageLeft" :href="bannerLinkLeft" target="_blank" class="d-inline-block">
					<v-img width="140" :src="sideBannerImageLeft" class="mx-auto" />
				</a>
			</v-col>

			<v-col cols="6">
				<v-col cols="12" sm="8" offset-md="2" class="text-center py-6">
					<router-link v-if="site" to="/">
						<v-img class="mx-auto" contain :max-height="site.logo.maxHeight" :src="site.logo.path | urlize('brand')" />
					</router-link>
				</v-col>

				<v-card v-if="!twoFactor" class="mx-auto" max-width="500">
					<h2 class="text-h5 text-center font-weight-bold pt-6 pb-3">
						Adviser Log In
					</h2>

					<v-alert v-if="displayHint" class="mx-5 mt-2 mb-4 subtitle-2 font-weight-regular" type="info" dense outlined text>
						HINT - both username and password are case sensitive
					</v-alert>

					<v-alert v-if="displayHint" class="mx-5 mt-3" outlined text color="error">
						<p class="text-body-1 font-weight-bold mb-0">
							Looking for your saved username/password?
						</p>
						<p class="text-body-2 mb-0">
							<a @click="openOldLogin">Click here</a> to open up a window from which you can copy/paste any saved credentials from our retired URL.
						</p>
					</v-alert>

					<v-sheet class="body pa-5 pt-0">
						<dynamic-form
							ref="loginForm"
							:form-schema="form('login').value"
							:form-data="formData"
							:submit-button-loading="loading"
							submit-button-text="Log in"
							:submit-button-block="true"
							:submit-button-large="true"
							@dynamic-form-submit="submit"
						/>

						<v-btn color="blue-grey lighten-1" text class="mt-3 mx-auto text-none d-flex" to="/password-recovery">
							Forgotten your password?
						</v-btn>

						<v-divider class="my-6" />

						<p class="text-body-1 font-weight-bold mb-0">
							New here?
						</p>
						<p class="text-body-2">
							No problem, signing up is quick and easy. See you on the other side!
						</p>
						<v-btn color="primary" outlined block large class="mt-3 mx-auto d-flex" to="/register">
							Create an account
						</v-btn>
					</v-sheet>
				</v-card>
				<v-card v-else class="mx-auto" max-width="500">
					<v-sheet class="body pa-5">
						<h3 class="pb-3">
							Two Factor Authentication
						</h3>
						<v-divider />
						<p class="font-weight-medium pt-3">
							Please enter a 2FA code generated by your authenticator application in the field below.
						</p>

						<dynamic-form
							ref="twoFactorForm"
							:form-schema="form('two-factor').value"
							:form-data="twoFactorFormData"
							:submit-button-loading="loading"
							:submit-button-block="true"
							:submit-button-large="true"
							@dynamic-form-submit="submit"
						/>
					</v-sheet>
				</v-card>

				<v-sheet color="rgba(255, 0, 0, 0)" max-width="500" class="mx-auto pt-6">
					<v-row no-gutters>
						<v-col cols="12" sm="6">
							<v-btn text x-small class="text-none" @click="openContactBox">
								Help
							</v-btn>
						</v-col>
						<v-col cols="12" sm="6" class="text-caption black--text mb-0 text-right">
							<v-btn text x-small class="text-none" @click="openTerms">
								Terms
							</v-btn>
							<v-btn text x-small class="text-none" @click="openPrivacy">
								Privacy Notice
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>

			<v-col cols="3" class="text-center pt-6">
				<a v-if="hasBanner && sideBannerImageRight" :href="bannerLinkRight" target="_blank" class="d-inline-block">
					<v-img width="140" :src="sideBannerImageRight" class="mx-auto" />
				</a>
			</v-col>
		</v-row>
		<app-dialog-contact ref="contact" />
	</div>
</template>

<script>
	import { debounce } from 'lodash';
	import { mapGetters, mapState, mapActions } from 'vuex';
	import { EventBus, ElementTools, PopupWindow } from '@/utils';
	import DynamicForm from '@/component/common/base/dynamic-form';
	import AppDialogContact from '@/component/app/dialog/contact';

	export default {
		name: 'app-login',

		components: { DynamicForm, AppDialogContact },

		data() {
			return {
				displayHint: false,
				formData: {},
				twoFactorFormData: {},
				loading: false,
				twoFactor: false,
				banner: null
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('Account', ['user']),
			...mapGetters('CmsForm', ['form']),
			...mapGetters('Account', ['userSecurityQuestion']),

			currentRouteName() {
				return this.$route.name;
			},

			hasBanner() {
				return this.site.adverts?.login;
			},

			topBannerLink() {
				return this.banner?.value ? this.banner?.value?.horizontal?.link : '';
			},

			topBannerImage() {
				return this.banner?.value ? this.$options.filters.urlize(this.banner.value?.horizontal?.image) : '';
			},

			bannerLinkRight() {
				return this.banner?.value ? this.banner?.value?.right?.link : '';
			},

			sideBannerImageRight() {
				return this.banner?.value ? this.$options.filters.urlize(this.banner.value?.right?.image) : '';
			},

			bannerLinkLeft() {
				return this.banner?.value ? this.banner?.value?.left?.link : '';
			},

			sideBannerImageLeft() {
				return this.banner?.value ? this.$options.filters.urlize(this.banner.value?.left?.image) : '';
			}
		},

		created() {
			this.loadForm('login');
			this.loadLoginBanner().then(({ data }) => {
				this.banner = data;
			});
		},

		beforeDestroy() {
			ElementTools.hideNotification(this.$root.$el);
		},

		methods: {
			...mapActions('Account', ['login', 'loadUserDetails']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsSite', ['loadSite']),
			...mapActions('CmsBanner', ['loadLoginBanner']),

			/**
			 * @debounce @name openContactBox
			 * @description Open the contact box
			 */
			openContactBox: debounce(function() {
				this.$refs.contact.open();
			}, 200),

			openTerms() {
				EventBus.$emit('open-terms');
			},

			openPrivacy() {
				EventBus.$emit('open-privacy');
			},

			async submit() {
				this.displayHint = false;
				this.loading = true;

				try {
					await this.login({ ...this.formData, ...this.twoFactorFormData });
					await this.loadUserDetails(this.user.id); // Need to check if user has answered security questions

					await this.loadSite();
					const { redirect, ...query } = this.$route.query;
					const redirectPath = Object.keys(this.user.userSecurityQuestion).length === 0 ? '/security-questions' : (redirect || '/account');
					this.$router.push({ path: redirectPath, query });
				} catch (err) {
					if (err.data?.twoFactor) {
						await this.loadForm('two-factor');
						this.twoFactor = true;
					} else {
						ElementTools.fireNotification(this.$root.$el, 'error', { text: err.data?.message || 'Login failed, please try again', timeout: 0, close: true });
						this.displayHint = true;
					}
					this.loading = false;
				}
			},

			openOldLogin() {
				const width = 600;
				const height = 500;
				const top = screen.height / 2 - height / 2;
				const left = screen.width / 2 - width / 2;
				let obj = { name: 'oldUrl', url: 'https://airsourcing.co.uk/old-login', width, height, top, left };
				new PopupWindow().openPopup(obj);
			}
		}
	};
</script>
