<!--
	@name app-account-settings-security
	@description Account settings - Account security tab content
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-5">
		<common-structure-section class="flex-grow-1 elevation-2">
			<template #header>
				<slot>Change Your Security Questions</slot>
			</template>
			<template #body>
				<validation-observer v-slot="{ invalid, validate }">
					<v-alert v-if="selectedSecurityQuestions.length !== 3" dense text outlined type="error" class="mb-3 body-2">
						You must select 3 security questions before you can update your details
					</v-alert>

					<v-chip-group v-model="selectedSecurityQuestions" color="success" max="3" column multiple>
						<v-chip v-for="(question, index) in securityQuestionList" :key="index" :value="question.id">
							{{ question.name }}
						</v-chip>
					</v-chip-group>
					<form class="mt-5" @submit.prevent="validate().then(submitSecurityQuestions)">
						<validation-provider
							v-for="answer in securityQuestionList"
							:key="answer.id"
							v-slot="{ errors }"
							:vid="answer.id"
							:name="answer.name"
							:rules="{ required: selectedSecurityQuestions.includes(answer.id) }"
						>
							<v-text-field
								v-show="selectedSecurityQuestions.includes(answer.id)"
								v-model="securityFormData[answer.id]"
								:disabled="!selectedSecurityQuestions.includes(answer.id)"
								dense
								:label="answer.name"
								outlined
								:placeholder="answer.name"
								:error-messages="errors"
							/>
						</validation-provider>
						<v-btn color="primary" class="mx-auto d-block" :disabled="invalid || selectedSecurityQuestions.length !== 3" type="submit">
							Update
						</v-btn>
					</form>
				</validation-observer>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';
	import { ElementTools } from '@/utils';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { map, find } from 'lodash';

	export default {
		name: 'app-account-settings-security-security-questions',

		components: {
			CommonStructureSection,
			ValidationProvider,
			ValidationObserver
		},

		data() {
			return {
				submitting: false,
				selectedSecurityQuestions: [],
				securityFormData: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('AccountSecurityQuestion', ['securityQuestionList']),
			...mapGetters('Account', ['userSecurityQuestion', 'isAdmin'])
		},

		created() {
			this.setFormData();
		},

		methods: {
			...mapActions('Account', [
				'loadUserDetails',
				'modifyUserSecurityQuestion',
				'addUserSecurityQuestion',
				'deleteUserSecurityQuestion'
			]),
			...mapActions('AccountSecurityQuestion', ['loadSecurityQuestionList']),

			async setFormData() {
				// set security questions form data
				if (!this.securityQuestionList.length) await this.loadSecurityQuestionList();
				this.securityQuestionList.forEach((securityQuestion) => this.$set(this.securityFormData, securityQuestion.id, null));
				this.selectedSecurityQuestions = this.user.userSecurityQuestion.map((userSecurityQuestion) => userSecurityQuestion?.securityQuestion?.id);
				this.user.userSecurityQuestion.forEach((answer) => {
					if (answer?.securityQuestion?.id in this.securityFormData) this.securityFormData[answer.securityQuestion.id] = answer.answer;
				});
			},

            /**
			 * @name submitSecurityQuestions
			 * @description Triggered when sec questions updated and saved
			 * @param {Object} payload
			 */
			async submitSecurityQuestions() {
				this.submitting = true;
				const promises = [];
				const currentItems = map(this.userSecurityQuestion, (securityQuestion) => securityQuestion?.securityQuestion?.id);
				for (const [id, answer] of Object.entries(this.securityFormData)) {
					let existingItem = currentItems.includes(id);
					let activeItem = this.selectedSecurityQuestions.includes(id);
					if (existingItem && activeItem) {
						//Update existing item
						let curAnswer = find(this.userSecurityQuestion, (securityQuestion) => securityQuestion?.securityQuestion?.id == id);
						if (curAnswer && curAnswer?.answer != answer) promises.push(this.modifyUserSecurityQuestion({ id: curAnswer.id, answer: answer }));
					} else if (!existingItem && activeItem) {
						//Insert new item
						promises.push(this.addUserSecurityQuestion({ payload: { securityQuestionId: id, answer: answer } }));
					} else if (existingItem && !activeItem) {
						//Delete discarded item
						let curAnswer = find(this.userSecurityQuestion, (securityQuestion) => securityQuestion?.securityQuestion?.id == id);
						if (curAnswer) promises.push(this.deleteUserSecurityQuestion(curAnswer.id));
					}
				}

				Promise.all(promises)
					.then(() => {
						this.loadUserDetails(this.user.id);
						ElementTools.fireNotification(this.$el, 'success', 'Your details have been successfully updated');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update details'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>