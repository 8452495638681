<!--
	@name app-dashboard
	@description Dashboard page
	@date 2020/05/12
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<div v-if="isAdmin" class="mt-8">
			<div class="text-h4 font-weight-bold mb-4 text-center">
				Air Sourcing Admin Dashboard
			</div>

			<div class="text-h6 font-weight-regular mb-8 text-center">
				<a class="font-weight-bold" @click="accountTakeover">Take over an adviser account to begin</a>
			</div>
		</div>
		<app-dashboard-onboarding v-else-if="!user.onboarded" />
		<v-row v-else>
			<v-col class="py-0" cols="12" md="8">
				<!-- Welcome text -->
				<div v-if="contentDashboard.text" class="border-effect border-effect--lg mt-2 ml-6 mb-10">
					<p class="text-h6 mb-2 font-weight-bold">
						<common-base-dynamic-content :html-content="contentDashboard.text.introduction" />
					</p>
				</div>
				<v-skeleton-loader v-else type="paragraph" />

				<v-row v-if="hasHeroBanner && heroBannerImage" no-gutters class="mx-auto mb-6" style="max-width: 1000px">
					<v-col cols="12" class="text-center">
						<a :href="heroBannerLink" target="_blank">
							<v-img :src="heroBannerImage" class="mx-auto" max-height="120" max-width="1000" contain />
						</a>
					</v-col>
				</v-row>

				<app-dashboard-totalizer v-if="displayTotalizer" ref="campaign" class="mb-6" />

				<v-sheet v-if="!authed" color="white">
					<v-skeleton-loader type="list-item-three-line" />
				</v-sheet>

				<div v-else>
					<v-row>
						<v-col class="py-0" cols="12" md="8">
							<!-- OnAir -->
							<app-dashboard-on-air v-if="hasOnAirAccess" />
							<!-- Rate changes -->
							<app-dashboard-product-changes v-else-if="displayRates" :large="true" />
						</v-col>
						<v-col class="py-0" cols="12" md="4">
							<app-dashboard-api-health />
						</v-col>
					</v-row>

					<!-- Tabs -->
					<common-base-tab-view :tabs="activeTabs" router-view auto-switch />
				</div>
			</v-col>

			<v-col class="py-0" cols="6" md="4">
				<!-- Shortcuts -->
				<common-link-shortcuts :items="filteredShortcuts" />

				<!-- Countdown -->
				<app-dashboard-countdown v-if="countDownActive" ref="countdown" section="dashboard" />

				<!-- Rate changes -->
				<app-dashboard-product-changes v-if="hasOnAirAccess && displayRates" />

				<!-- Air Rewards -->
				<common-statistic-reward-counter v-if="hasRewardsAccess" :total="user.rewardsBalance" section="dashboard" />

				<!-- Adverts -->
				<common-promotion-advisor-advert class="mt-6" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
	const COUNTDOWN_STORAGE_NAME = 'fitForTheFuture2025';
	const CAMPAIGN_STORAGE_NAME = 'kfiCharity2023';

	import { mapState, mapGetters, mapActions } from 'vuex';
	import { $localStorage } from '@/utils/storage';
	import { EventBus } from '@/utils';
	import CommonBaseDynamicContent from '@/component/common/base/dynamic-content';
	import CommonBaseTabView from '@/component/common/base/tab-view';
	import CommonStatisticRewardCounter from '@/component/common/statistic/reward-counter';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import CommonLinkShortcuts from '@/component/common/link/shortcuts';
	import AppDashboardTotalizer from '@/component/app/dashboard/totalizer';
	import AppDashboardOnAir from '@/component/app/dashboard/on-air';
	import AppDashboardProductChanges from '@/component/app/dashboard/product-changes';
	import AppDashboardApiHealth from '@/component/app/dashboard/api-health';
	import AppDashboardOnboarding from '@/component/app/dashboard/onboarding';
	import AppDashboardCountdown from '@/component/app/dashboard/countdown';

	export default {
		name: 'app-dashboard',

		components: {
			CommonBaseDynamicContent,
			CommonBaseTabView,
			CommonStatisticRewardCounter,
			CommonPromotionAdvisorAdvert,
			CommonLinkShortcuts,
			AppDashboardTotalizer,
			AppDashboardOnAir,
			AppDashboardProductChanges,
			AppDashboardApiHealth,
			AppDashboardOnboarding,
			AppDashboardCountdown
		},

		data() {
			return {
				displayRates: true,
				reward: {},
				tabs: [
					{ title: 'Latest Activity', alerts: 0, name: 'dashboard.activity', path: '/account' },
					{ title: 'News', alerts: 0, name: 'dashboard.news', path: '/account/news' },
					{ title: 'Events', alerts: 0, name: 'dashboard.events', path: '/account/events' }
				],
				heroBanner: null
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('AppProduct', ['latestProductChanges']),
			...mapState('CmsSite', ['site']),
			...mapState('CmsNews', ['newsList']),
			...mapGetters('CmsEvent', ['upcomingEvents']),
			...mapGetters('Account', ['authed', 'isAdmin','userSecurityQuestion']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsContent', ['contentDashboard']),
			...mapGetters('CmsData', ['data']),
			...mapGetters('CmsShortcut', ['shortcutDashboard']),
			...mapGetters('CmsSite', ['restrictedRoutes']),

			activeTabs() {
				if (!this.site.usesMortgageClub) return [this.tabs[0]];
				return this.tabs;
			},

			hasOnAirAccess() {
				return !this.restrictedRoutes.includes('onAir');
			},

			hasRewardsAccess() {
				return !this.restrictedRoutes.includes('airRewards');
			},

			hasBanner() {
				return this.site.adverts?.advisor;
			},

			hiddenShortcuts() {
				let hiddenShortcuts = [];

				if (this.membership('select').id && !this.membership('select').bypass) hiddenShortcuts.push('airClub');

				return hiddenShortcuts;
			},

			filteredShortcuts() {
				return this.shortcutDashboard?.list?.filter((item) => !this.hiddenShortcuts.includes(item.nameUnique));
			},

			countDownActive() {
				// return false;
				return !!COUNTDOWN_STORAGE_NAME;
			},

			campaignActive() {
				return !!CAMPAIGN_STORAGE_NAME;
			},

			displayTotalizer() {
				return false;
				//return this.site.usesMortgageClub;
			},

			hasHeroBanner() {
				return this.site.adverts?.advisor;
			},

			heroBannerLink() {
				return this.heroBanner ? this.heroBanner?.link : '';
			},

			heroBannerImage() {
				return this.heroBanner ? this.$options.filters.urlize(this.heroBanner?.image) : '';
			}
		},

		async created() {
			console.log('wppapap' ,this.user.userSecurityQuestion);
			if (this.isAdmin) return;

			this.loadContent('dashboard');
			this.loadShortcut('dashboard');
			this.loadData('advisor-advert');

			if (this.site.usesMortgageClub && !this.newsList?.length) {
				this.loadNewsList().then(
					() =>
						(this.tabs[1].alerts = this.newsList.filter(
							(item) => !this.user.loginPrevious || this.$moment(item.created) > this.$moment(this.user.loginPrevious)
						).length)
				);
			}

			if (this.site.usesMortgageClub && !this.upcomingEvents?.length) {
				this.loadEventsList().then(
					() =>
						(this.tabs[2].alerts = this.upcomingEvents.filter(
							(item) => !this.user.loginPrevious || this.$moment(item.created) > this.$moment(this.user.loginPrevious)
						).length)
				);
			}

			this.loadAdvisorBanner().then(({ data }) => {
				if (data?.value.sourcing_hero) this.heroBanner = data.value.sourcing_hero;
			});
		},

		mounted() {
			if (this.isAdmin) return;
			this.initCountdown();
			this.initCampaign();
		},

		methods: {
			...mapActions('Account', ['loadUserDetails']),
			...mapActions('CmsContent', ['loadContent']),
			...mapActions('CmsShortcut', ['loadShortcut']),
			...mapActions('CmsData', ['loadData']),
			...mapActions('CmsProductUpdate', ['loadLatestProductChanges']),
			...mapActions('CmsNews', ['loadNewsList']),
			...mapActions('CmsEvent', ['loadEventsList']),
			...mapActions('CmsBanner', ['loadAdvisorBanner']),

			accountTakeover() {
				EventBus.$emit('account-takeover');
			},

			initCountdown() {
				if (!this.$refs.countdown) return;
				if (this.countDownActive && !$localStorage.isDialogHidden(COUNTDOWN_STORAGE_NAME)) {
					this.$refs.countdown.open('dialog');
					$localStorage.hideDialog(COUNTDOWN_STORAGE_NAME);
				} else this.$refs.countdown.open();
			},

			initCampaign() {
				if (!this.$refs.campaign) return;
				if (this.campaignActive && !$localStorage.isDialogHidden(CAMPAIGN_STORAGE_NAME)) {
					this.$refs.campaign.open();
					$localStorage.hideDialog(CAMPAIGN_STORAGE_NAME);
				}
			}
		}
	};
</script>
