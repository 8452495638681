<!--
	@name app-security-questions
	@description Security Questions page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Security Questions
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-container class="ml-0 pa-0">
			<v-row no-gutters>
				<v-col cols="12">
					<div class="border-effect border-effect--lg ml-6">
						<p class="text-h5 font-weight-regular mb-3">
							Please complete your security questions
						</p>
						<p class="text-body-1 mb-6">
							To keep your account safe when contacting our team, we will ask you to answer these questions as part of our security checks.
						</p>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<app-account-settings-security-security-questions />
	</div>
</template>

<script>
	import AppAccountSettingsSecuritySecurityQuestions from '@/component/app/account-settings/security/security-questions';

	export default {
		name: 'app-security-questions',

		components: {
			AppAccountSettingsSecuritySecurityQuestions
		},

		computed: {
		},

		created() {
		},

		methods: {
		}
	};
</script>
