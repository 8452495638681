<!--
	@name app-account-settings-security
	@description Account settings - Account security tab content
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-5">
		<p class="text-body-1 mb-6">
			Here you can amend your security details, including 2FA.
		</p>
		<v-item-group v-model="model" class="d-flex flex-wrap ma-n3" style="max-width: auto">
			<v-item v-for="section in sectionList" :key="section.id" v-slot="{ active, toggle }" :value="section.id - 1">
				<v-hover>
					<template #default="{ hover }">
						<v-card
							class="item ma-3 flex-grow-1"
							:class="[active ? '' : section.color, active ? 'white--text secondary' : 'grey lighten-4']"
							:elevation="hover ? 4 : 2"
							height="100"
							@click="toggle"
						>
							<v-row class="fill-height" align="center" justify="center" no-gutters>
								<v-col class="px-6 text-center">
									<p class="text-body-1 font-weight-bold mb-0">
										{{ section.name }}
									</p>
									<v-scale-transition>
										<v-icon v-if="active" color="white" size="30" class="mt-1 d-none" v-text="'mdi-close-circle-outline'" />
									</v-scale-transition>
								</v-col>
							</v-row>
						</v-card>
					</template>
				</v-hover>
			</v-item>
		</v-item-group>

		<v-expand-transition>
			<v-sheet v-if="model != null" tile class="pt-6">
				<common-structure-section v-if="model === 0" class="flex-grow-1 elevation-2">
					<template #header>
						<slot>Change Your Email Address</slot>
					</template>
					<template #body>
						<dynamic-form
							submit-button-text="Update Email"
							:form-schema="form('account-security-email-address').value"
							:form-data="emailFormData"
							:custom-error="customError"
							:submit-button-loading="submitting"
							@dynamic-form-submit="submitEmail"
							@form-updated="customError = {}"
						/>
					</template>
				</common-structure-section>

				<common-structure-section v-if="model === 1" class="flex-grow-1 elevation-2">
					<template #header>
						<slot>Change Your Username</slot>
					</template>
					<template #body>
						<dynamic-form
							submit-button-text="Update Username"
							:form-schema="form('account-security-username').value"
							:form-data="usernameFormData"
							:custom-error="customError"
							:submit-button-loading="submitting"
							@dynamic-form-submit="submitUsername"
							@form-updated="customError = {}"
						/>
					</template>
				</common-structure-section>

				<common-structure-section v-if="model === 2" class="flex-grow-1 elevation-2">
					<template #header>
						<slot>Change Your Password</slot>
					</template>
					<template #body>
						<dynamic-form
							submit-button-text="Update Password"
							:form-schema="form('account-security-password').value"
							:form-data="{}"
							:submit-button-loading="submitting"
							@dynamic-form-submit="submitPassword"
						/>
					</template>
				</common-structure-section>

				<app-account-settings-security-security-questions v-if="model === 3" />

				<common-structure-section v-if="model === 4" class="flex-grow-1 elevation-2">
					<template #header>
						<slot>Change Your Two Factor Auth</slot>
					</template>
					<template #body>
						<p class="text-body-2">
							2FA protects against phishing, social engineering and password brute-force attacks and secures your logins from attackers exploiting weak or stolen
							credentials.
						</p>
						<p v-if="!user.twoFactor" class="text-body-2">
							<span class="font-weight-medium error--text">Two-factor authentication is currently disabled on this account.</span> Our 2FA process requires that you
							have an authenticator app, such as
							<a
								class="font-weight-medium"
								href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
								target="_blank"
							>Google Authenticator</a>, available.
						</p>

						<div class="text-center mt-5">
							<v-btn v-if="!codes.qr" :loading="generating" color="primary" @click="generateTwoFactorCodes">
								Generate New QR Code
							</v-btn>
							<v-btn v-if="codes.qr && !user.twoFactor" color="error" dark @click="codes = {}">
								Cancel
							</v-btn>
							<v-btn v-if="user.twoFactor" :loading="deactivating" color="error" dark class="ml-3" @click="deactivateTwoFactorDialog">
								Deactivate 2FA
							</v-btn>
						</div>

						<div v-if="codes.qr">
							<v-img :src="codes.qr" class="d-block mx-auto mt-5" max-width="200" />
							<p v-if="codes.secret" class="text-body-2 text-center">
								{{ codes.secret }}
							</p>
							<div class="col-md-8 mx-auto pa-0">
								<dynamic-form
									submit-button-text="Confirm 2FA code"
									:form-schema="form('account-security-two-factor').value"
									:form-data="{}"
									:submit-button-loading="submitting"
									@dynamic-form-submit="submitTwoFactor"
								/>
							</div>
						</div>
						<common-dialog-confirm ref="confirm" />
					</template>
				</common-structure-section>
			</v-sheet>
		</v-expand-transition>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex';
	import AppAccountSettingsSecuritySecurityQuestions from '@/component/app/account-settings/security/security-questions';
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicForm from '@/component/common/base/dynamic-form';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-account-settings-tab-security',

		components: {
			CommonStructureSection,
			DynamicForm,
			CommonDialogConfirm,
			AppAccountSettingsSecuritySecurityQuestions
		},

		data() {
			return {
				submitting: false,
				generating: false,
				deactivating: false,
				codes: {},
				loading: {},
				model: null,
				parsedFormData: {},
				parsedFormSchema: [],
				sections: [
					{ id: 1, name: 'Change Your Email Address', color: 'success--text' },
					{ id: 2, name: 'Change Your Username', color: ' blue--text text--darken-4' },
					{ id: 3, name: 'Change Your Password', color: 'yellow--text text--darken-2' },
					{ id: 4, name: 'Change Your Security Questions', color: 'purple--text text--darken-2' },
					{ id: 5, name: 'Two Factor Authentication', color: 'error--text ' }
				],
				emailFormData: {},
				usernameFormData: {},
				securityFormData: {},
				customError: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapGetters('Account', ['isAdmin']),
			...mapGetters('CmsForm', ['form']),

			sectionList() {
				return this.sections.filter((section) => !this.isAdmin || section.id !== 4);
			}
		},

		created() {
			this.setFormData();
			this.loadFormList(['account-security-email-address', 'account-security-username', 'account-security-password', 'account-security-two-factor', 'account-details']);
		},

		methods: {
			...mapActions('Account', [
				'loadUserDetails',
				'generateTwoFactor',
				'deactivateTwoFactor',
				'modifyTwoFactor',
				'modifyUserIdentity',
				'modifyUserAccount',
				'modifyUserDetail'
			]),
			...mapActions('CmsForm', ['loadFormList']),

			async setFormData() {
				// set email form data
				const email = this.user.userDetail.email;
				this.emailFormData = { email };

				// set username form data
				const username = this.user.userIdentity.find((ui) => ui.type === 'username') || {};
				this.usernameFormData = { username: username.identity };
			},

			/**
			 * @name submitEmail
			 * @description Event listener for form submissions
			 * @param {Object} payload
			 */
			async submitEmail(data) {
				this.submitting = true;
				const payload = { email: data.email };

				this.modifyUserDetail(payload)
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Email address successfully updated'))
					.catch((error) => {
						let uniqueValidationError;
						if (error.status === 422) {
							uniqueValidationError = error.data?.find((e) => e.label === 'email');
							if (uniqueValidationError) this.$set(this.customError, 'email', uniqueValidationError.message);
						}

						if (!uniqueValidationError) ElementTools.fireNotification(this.$el, 'error', 'Failed to update email address');
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submitUsername
			 * @description Event listener for form submissions
			 * @param {Object} payload
			 */
			async submitUsername(data) {
				this.submitting = true;
				let identity = this.user.userIdentity.find((u) => u.type === 'username');
				let payload = { identity: data.username };

				this.modifyUserIdentity({
					identity,
					payload
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Username successfully updated'))
					.catch((error) => {
						let uniqueValidationError;
						if (error.status === 422) {
							uniqueValidationError = error.data?.find((e) => e.label === 'identity');
							if (uniqueValidationError) this.$set(this.customError, 'username', uniqueValidationError.message);
						}

						if (!uniqueValidationError) ElementTools.fireNotification(this.$el, 'error', 'Failed to update username');
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submitPassword
			 * @description Event listener for form submissions
			 * @param {Object} payload
			 */
			async submitPassword(data) {
				this.submitting = true;

				this.modifyUserAccount({ password: data.password, oldPassword: data.old_password })
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Password successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update password'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submitTwoFactor
			 * @description Event listener for form submissions
			 * @param {Object} payload
			 */
			async submitTwoFactor(data) {
				// this.submit('security-two-factor', payload);
				this.submitSecurityTwoFactor(data).then(() => (this.submitting = false));
			},

			/**
			 * @name submitSecurityTwoFactor
			 * @description Event listener for form submissions
			 * @param {Object} payload
			 */
			async submitSecurityTwoFactor(data) {
				this.submitting = true;

				return this.modifyTwoFactor(data)
					.then(() => {
						this.user.twoFactor = true;
						ElementTools.fireNotification(this.$el, 'success', 'Updated two factor successfully');
						this.codes = {};
					})
					.catch((error) => ElementTools.fireNotification(this.$el, 'error', error?.data?.message || 'Failed to validate user 2FA: Invalid Code'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name generateTwoFactor
			 * @description Generate two factor codes
			 */
			generateTwoFactorCodes() {
				this.generating = true;
				this.generateTwoFactor()
					.then((codes) => (this.codes = codes))
					.catch(() => {
						// NOTE: [paul] need to run this through notification system
						console.log('Could not generate codes for two factor');
					})
					.then(() => (this.generating = false));
			},

			/**
			 * @name deactivateTwoFactorDialog
			 * @description TODO
			 */
			deactivateTwoFactorDialog() {
				this.$refs.confirm
					.open('Deactivate 2FA', 'Are you sure that you wish to deactivate 2FA for your account?', {
						system_bar_color: 'error darken-2',
						app_bar_color: 'error',
						buttons: { yes: 'Yes, deactivate', no: 'Cancel' }
					})
					.then(() => {
						this.deactivateTwoFactorCodes();
					})
					.catch((e) => {
						if (e) console.error(e);
					});
			},

			/**
			 * @name deactivateTwoFactor
			 * @description Deactivate two factor
			 */
			deactivateTwoFactorCodes() {
				this.deactivating = true;
				this.deactivateTwoFactor()
					.then(() => {
						this.codes = {};
						this.user.twoFactor = false;
					})
					.catch(() => {
						// NOTE: [paul] need to run this through notification system
						console.log('Could not 2FA');
					})
					.then(() => (this.deactivating = false));
			}
		}
	};
</script>

<style scoped lang="scss">
	.item {
		flex-basis: 30%;
	}
</style>
